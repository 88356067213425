const X = () => {
    return(
        <svg viewBox='0 0 54 48.812' >
            <path d='M44.594,24.666a7.03,7.03,0,0,0,1.6-1.967,27.992,27.992,0,0,0,1.867-3.667A28.827,28.827,0,0,0,49.694,12.9a55.461,55.461,0,0,0,.767-8.833,1.342,1.342,0,0,0-.367-0.933,1.25,1.25,0,0,0-.967-0.4H32.394a1.171,1.171,0,0,0-.9.4,1.337,1.337,0,0,0-.367.933,38.975,38.975,0,0,1-.567,6.3,18.872,18.872,0,0,1-1.067,3.967,6.182,6.182,0,0,1-1.3,1.967,3.509,3.509,0,0,1-1.067.8,1.472,1.472,0,0,1-1.067,0,3.484,3.484,0,0,1-1.067-.8,6.145,6.145,0,0,1-1.3-1.967,18.8,18.8,0,0,1-1.067-3.967,38.874,38.874,0,0,1-.567-6.3,1.342,1.342,0,0,0-.367-0.933,1.175,1.175,0,0,0-.9-0.4H4.061a1.248,1.248,0,0,0-.967.4,1.337,1.337,0,0,0-.367.933,53.251,53.251,0,0,0,.8,8.867,31.9,31.9,0,0,0,1.6,6.1A20.108,20.108,0,0,0,7.061,22.7a11.682,11.682,0,0,0,1.5,1.933,7.857,7.857,0,0,0,.633.5l-0.6.467a8.544,8.544,0,0,0-1.6,2,25.917,25.917,0,0,0-1.867,3.633,28.784,28.784,0,0,0-1.633,6.133A55.329,55.329,0,0,0,2.727,46.2a1.337,1.337,0,0,0,.367.933,1.248,1.248,0,0,0,.967.4H20.794a1.252,1.252,0,0,0,1.267-1.267,39.273,39.273,0,0,1,.567-6.367,18.008,18.008,0,0,1,1.067-3.933A6.678,6.678,0,0,1,24.994,34a3.405,3.405,0,0,1,1.067-.833,1.472,1.472,0,0,1,1.067,0A3.429,3.429,0,0,1,28.194,34a6.721,6.721,0,0,1,1.3,1.967A18.072,18.072,0,0,1,30.561,39.9a39.375,39.375,0,0,1,.567,6.367,1.252,1.252,0,0,0,1.267,1.267H49.127a1.25,1.25,0,0,0,.967-0.4,1.341,1.341,0,0,0,.367-0.933,52.821,52.821,0,0,0-.8-8.833,35.346,35.346,0,0,0-1.567-6.1,17.543,17.543,0,0,0-1.933-3.7,12.717,12.717,0,0,0-1.533-1.933c-0.29-.244-0.5-0.41-0.633-0.5Z'/>
        </svg>
    )
}

export default X

