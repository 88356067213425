import ProjectsShadow from '@projects/svgFilters/ProjectsShadow'
import './ChatAnimation.scss'
const ChatAnimation = ({ className }) => {
    return(
        <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 274.25 331.07'>
            <defs>
                <ProjectsShadow id='chat-shadow'/>
            </defs>
            <path className='fill-primary' d='M33.67,59.83A26.17,26.17,0,1,1,59.83,33.67,26.19,26.19,0,0,1,33.67,59.83Z'/>
            <path d='M33.67,15A18.67,18.67,0,1,1,15,33.67,18.66,18.66,0,0,1,33.67,15m0-15A33.67,33.67,0,1,0,67.33,33.67,33.7,33.7,0,0,0,33.67,0Z'/>
            <circle className='fill-primary' cx='122.79' cy='111.12' r='52.16' transform='translate(-33.18 55.45) rotate(-22.5)'/>
            <path d='M122.79,70a41.16,41.16,0,1,1-41.16,41.15A41.15,41.15,0,0,1,122.79,70m0-22A63.16,63.16,0,1,0,186,111.12,63.23,63.23,0,0,0,122.79,48Z'/>
            <rect className='fill-primary' x='133.43' y='114.66' width='134.6' height='102.36' rx='6.63' />
            <rect x='142.91' y='187.45' width='115.67' height='21.69' rx='3' />
            <circle className='rotate' cx='252.91' cy='132.9' r='5.71' />
            <g className='lines'>
                <rect x='167.5' y='130.3' width='72.71' height='5.2' rx='1' />
                <rect x='167.5' y='147.7' width='72.71' height='5.2' rx='1' />
                <rect x='167.5' y='164.7' width='72.71' height='5.2' rx='1' />
            </g>
            <polygon className='rotate' points='252.91 161.9 247.73 170.86 258.08 170.86 252.91 161.9' />
            <path d='M44.75,262.9a22.79,22.79,0,0,1-7.62,1.32c-8.16,0-13-5.1-13-13.2,0-9,5.64-13.38,12.9-13.38a20.05,20.05,0,0,1,7.68,1.5l1.86-7.26a24,24,0,0,0-10-1.8c-12.24,0-22.08,7.68-22.08,21.48,0,11.52,7.2,20.22,21.18,20.22,4.92,0,8.7-.9,10.38-1.74Zm6.9,8.28h9.12v-17.4a5.68,5.68,0,0,1,.3-2,4.85,4.85,0,0,1,4.56-3.18c3.42,0,4.74,2.64,4.74,6.48v16.14h9.12V254c0-8.82-4.44-12.84-10.26-12.84a10.39,10.39,0,0,0-4.74,1.14,11,11,0,0,0-3.6,3h-.12V228.58H51.65v42.6Zm60.05,0a47.28,47.28,0,0,1-.42-7.14v-10.2c0-6.9-3.12-12.66-12.9-12.66A24.39,24.39,0,0,0,87,243.76l1.68,5.88a16.62,16.62,0,0,1,8.1-2.22c4.5,0,5.34,2.22,5.34,3.78v.42c-10.38-.06-17.22,3.6-17.22,11.22,0,4.68,3.54,9,9.48,9a10.56,10.56,0,0,0,8.4-3.6h.18l.54,2.94Zm-9.3-10.68a5.84,5.84,0,0,1-.18,1.56,5,5,0,0,1-4.74,3.36,3.27,3.27,0,0,1-3.59-2.92,3.49,3.49,0,0,1,0-.56c0-3.54,3.72-4.68,8.52-4.62v3.18Zm16.87-25v6.36h-3.9v6.72h3.9v12c0,4.14.84,7,2.52,8.7a9.44,9.44,0,0,0,6.84,2.52,17.6,17.6,0,0,0,5.94-.78l-.06-6.9a13.69,13.69,0,0,1-2.7.24c-2.76,0-3.6-1.62-3.6-5.16V248.56h6.54v-6.72h-6.54v-8.4Z' />
            <rect x='140.54' y='129.1' width='14.75' height='43.6' rx='4' />
            <path className='rotate' d='M256.91,152.89l-2.72-2.71,2.71-2.71a.91.91,0,1,0-1.28-1.28l-2.72,2.71-2.72-2.7a.91.91,0,0,0-1.28,1.28l2.72,2.71-2.71,2.71a.91.91,0,0,0,0,1.28.89.89,0,0,0,1.28,0l2.72-2.72,2.72,2.71a.91.91,0,0,0,1.29,0A.9.9,0,0,0,256.91,152.89Z' />
            <path className='rotate' d='M273.66,256.66l-6-6,6-6a2,2,0,1,0-2.82-2.83l-6,6-6-6a2,2,0,1,0-2.82,2.84l6,6-6,6a2,2,0,1,0,2.83,2.83l6-6,6,6a2,2,0,1,0,2.82-2.84Z' />
            <circle cx='165.73' cy='262.84' r='12' />
            <path d='M162.59,265.18s21.89,44.59,49.48,62.11a23.55,23.55,0,0,0,11.74,3.76c6,.23,13.58-1.4,13.44-11.18a13.09,13.09,0,0,0-3.93-9c-3.75-3.75-12.46-7.77-21.23-13.29C212.09,297.58,163.31,268,162.59,265.18Z' />
            <circle cx='232.73' cy='269.84' r='12' />
        </svg>
    )
}

export default ChatAnimation