const Aws = () => {
	return(
		<svg className='aws' version='1.1' viewBox='14.7 23 42 26.2' xmlSpace='preserve' xmlns='http://www.w3.org/2000/svg'>
			<polygon points='27.09 35.764 25.984 40.34 28.182 40.34 27.115 35.764' />
			<path d='m16.302 40.744v0.666c0 3.311 3.579 6.66 7.991 6.66h23.533c4.412 0 7.991-3.35 7.991-6.66v-0.666c0-3.078-3.098-6.943-7.081-7.283-0.089-2.752-2.342-4.955-5.113-4.955-1.076 0-2.074 0.334-2.898 0.9-1.58-3.52-5.107-5.977-9.216-5.977-5.579 0-10.101 4.521-10.101 10.102 0 0.1 0.012 0.195 0.015 0.293-2.993 0.867-5.121 4.371-5.121 6.92zm12.699 3.055l-0.572-2.275h-2.717l-0.599 2.275h-1.547l2.639-9.283h1.898l2.444 9.283h-1.546zm9.012 0h-1.716l-1.196-6.994h-0.026l-1.183 6.994h-1.716l-1.795-9.283h1.496l1.221 7.215h0.027l1.221-7.215h1.561l1.248 7.254h0.026l1.209-7.254h1.469l-1.846 9.283zm5.433 0.181c-2.301 0-2.821-1.533-2.821-2.834v-0.221h1.482v0.234c0 1.131 0.494 1.703 1.521 1.703 0.936 0 1.403-0.664 1.403-1.354 0-0.975-0.493-1.404-1.325-1.65l-1.015-0.352c-1.353-0.52-1.937-1.221-1.937-2.547 0-1.691 1.144-2.627 2.886-2.627 2.379 0 2.626 1.482 2.626 2.443v0.209h-1.482v-0.195c0-0.846-0.377-1.34-1.3-1.34-0.637 0-1.248 0.352-1.248 1.34 0 0.793 0.403 1.195 1.392 1.572l1 0.365c1.313 0.467 1.886 1.184 1.886 2.457 1e-3 1.979-1.196 2.797-3.068 2.797z' />
			<path d='m26.205 34.516l-2.639 9.283h1.547l0.599-2.275h2.717l0.572 2.275h1.547l-2.444-9.283h-1.899zm-0.221 5.824l1.105-4.576h0.025l1.066 4.576h-2.196z' />
			<polygon points='37.181 41.77 37.154 41.77 35.906 34.516 34.346 34.516 33.125 41.73 33.098 41.73 31.877 34.516 30.381 34.516 32.176 43.799 33.892 43.799 35.074 36.805 35.101 36.805 36.297 43.799 38.013 43.799 39.858 34.516 38.39 34.516' />
			<path d='m44.629 38.729l-1-0.365c-0.988-0.377-1.392-0.779-1.392-1.572 0-0.988 0.611-1.34 1.248-1.34 0.923 0 1.3 0.494 1.3 1.34v0.195h1.482v-0.209c0-0.961-0.247-2.443-2.626-2.443-1.742 0-2.886 0.936-2.886 2.627 0 1.326 0.584 2.027 1.937 2.547l1.015 0.352c0.832 0.246 1.325 0.676 1.325 1.65 0 0.689-0.468 1.354-1.403 1.354-1.027 0-1.521-0.572-1.521-1.703v-0.234h-1.482v0.221c0 1.301 0.521 2.834 2.821 2.834 1.872 0 3.068-0.818 3.068-2.795 0-1.276-0.573-1.993-1.886-2.459z' />
		</svg>
	)
}

export default Aws
