import ProjectsShadow from '../svgFilters/ProjectsShadow'

const ExtensionsBlob = () => {
    return(
        <svg className='extensions-blob' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 671.47 647.9'>
            <defs>
                <ProjectsShadow id='extensions-blob-shadow'/>
            </defs>
            <path d='M29.5,359c34,22.77,65.27,49,95.27,76.54,28.05,25.75,55.19,52.49,82.9,78.6,27.23,25.65,54.32,51.46,82.13,76.46,22.88,20.57,48.47,37,78.24,46.35,36,11.31,72.22,15.22,109.1,5.31,18.1-4.86,34.48-13.14,47.58-26.94,20.18-21.27,24.72-46.85,20.18-74.77-4.31-26.51-16.19-50-30.31-72.37C504,451.3,496.51,433.23,493.27,413.7c-5.57-33.54,3.63-64.25,20.35-92.94,18.94-32.47,45.2-58.58,74.11-82.13a537.88,537.88,0,0,1,78-52.54c2-1.1,3.83-2.41,5.74-3.62l-.64-1.46-1,.18C96.22-269.58,9.25,254,.26,325.16c-.84,6.68.18,14.58,5.68,18.45' />
        </svg>
    )
}

export default ExtensionsBlob