const NodeJs = () => {
	return(
		<svg className='name node-js' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 105.4 53.87' xmlSpace='preserve'>
			<path d="M38.27,14.93,28.22,20.74a1.21,1.21,0,0,0-.61,1V33.39a1.17,1.17,0,0,0,.61,1l10.05,5.81a1.32,1.32,0,0,0,1.22,0l10-5.81a1.22,1.22,0,0,0,.6-1V21.78a1.19,1.19,0,0,0-.6-1l-10-5.81a1.31,1.31,0,0,0-.61-.17,1.28,1.28,0,0,0-.61.17" />
			<path d="M22.59,21.58A1.25,1.25,0,0,0,22,20.51L11.9,14.7a2.32,2.32,0,0,0-.55-.17h-.11a1,1,0,0,0-.55.17L.61,20.51A1.25,1.25,0,0,0,0,21.58L0,37.23a.56.56,0,0,0,.29.52.55.55,0,0,0,.6,0l6-3.44a1.24,1.24,0,0,0,.61-1.06V25.94a1.26,1.26,0,0,1,.61-1.07l2.54-1.47a1.16,1.16,0,0,1,.61-.18,1.08,1.08,0,0,1,.6.18l2.54,1.47A1.22,1.22,0,0,1,15,25.94v7.31a1.24,1.24,0,0,0,.61,1.06l6,3.44a.56.56,0,0,0,.61,0,.65.65,0,0,0,.29-.52Zm47.63,8.14a.3.3,0,0,1-.15.26l-3.46,2a.36.36,0,0,1-.32,0l-3.47-2a.31.31,0,0,1-.14-.26v-4a.31.31,0,0,1,.14-.26l3.47-2a.36.36,0,0,1,.32,0l3.46,2a.3.3,0,0,1,.15.26Zm1-29.63a.54.54,0,0,0-.6,0,.61.61,0,0,0-.29.52V16.12a.48.48,0,0,1-.21.37.38.38,0,0,1-.43,0L67.1,15.05a1.15,1.15,0,0,0-1.21,0L55.78,20.88a1.21,1.21,0,0,0-.61,1V33.59a1.25,1.25,0,0,0,.61,1.07L65.89,40.5a1.15,1.15,0,0,0,1.21,0l10.11-5.84a1.24,1.24,0,0,0,.61-1.07V4.51a1.23,1.23,0,0,0-.64-1.07Zm33.62,25.53a1.21,1.21,0,0,0,.61-1V21.75a1.26,1.26,0,0,0-.61-1.07l-10-5.83a1.15,1.15,0,0,0-1.21,0L83.45,20.68a1.22,1.22,0,0,0-.61,1.07V33.39a1.26,1.26,0,0,0,.61,1.07l10,5.72a1.24,1.24,0,0,0,1.18,0l6.07-3.38a.62.62,0,0,0,.32-.52.56.56,0,0,0-.32-.52L90.55,29.92a.59.59,0,0,1-.31-.52V25.74a.54.54,0,0,1,.31-.52L93.7,23.4a.56.56,0,0,1,.61,0l3.15,1.82a.59.59,0,0,1,.31.52v2.88a.54.54,0,0,0,.32.52.56.56,0,0,0,.61,0Z" />
			<path d="M94,25.07a.31.31,0,0,1,.23,0l1.94,1.13a.19.19,0,0,1,.11.2v2.22a.27.27,0,0,1-.11.21L94.22,30A.3.3,0,0,1,94,30l-1.93-1.12a.2.2,0,0,1-.12-.21V26.4a.19.19,0,0,1,.12-.2Z" />
		</svg>
	)
}

export default NodeJs